import React from 'react'
import { BrowserRouter,Routes,Route, useLocation } from 'react-router-dom'
import Home from './pages/home/Home'
import Promo from './pages/promo/Promo';
import Xl from './pages/xl/Xl';
import Wheel from './pages/wheel/Wheel';
import Puzzle from './pages/puzzle/Puzzle';
import axios from "axios";
import Error from './pages/error/Error';
import Sucsess from './pages/sucsess/Sucsess';
import Tearms from './pages/tearms/Tearms';
import { ProtectedRoute } from './util/ProtectedRoute';
import Jocker from './pages/jocker/Jocker';
import Register from './pages/login/Register';
import Login from './pages/login/Login';
import Footer from './components/footer/Footer';
import XlRank from './pages/xlRank/XlRank';
import XlPlus from './pages/xlRank/XlPlus';
import BtoB from './pages/btob/BtoB';
import XlPromos from './pages/xlPromos/XlPromos';
import Consumer from './pages/btob/Consumer';
import SuperLeague from './pages/superLeague/SuperLeague';
import TwentyFive from './pages/xlRank/TwentyFive';
import ConsumerRank from './pages/xlRank/ConsumerRank';
import JockerWheel from './pages/btob/JockerWheel';



// axios.defaults.baseURL = "http://localhost:4348/api/v1";
// axios.defaults.baseURL = "https://api.notionspromos.com/api";
axios.defaults.withCredentials = true;

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/wheel/:qr/:promotion_name" element={<JockerWheel />} />
        {/* <Route
          path="/wheel"
          element={
            <ProtectedRoute>
              <Wheel />
            </ProtectedRoute>
          }
        /> */}

        {/* <Route
          path="/puzzle"
          element={
            <ProtectedRoute>
              <Puzzle />
            </ProtectedRoute>
          }
        /> */}
        <Route path="/xl-ranks" element={<XlRank />} />
        <Route path="/xl-plus" element={<XlPlus />} />
        <Route path="/xl-twenty-five" element={<TwentyFive />} />
        <Route path="/xl-consumer-rank" element={<ConsumerRank />} />
        {/* <Route path="/jocker/:qr" element={<Jocker />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/promotion/:value/:promolink" element={<Promo />} />
        <Route path="/promotion" element={<Promo />} />
        <Route path="*" element={<Error />} />
        <Route path="/:value/:promolink" element={<Home />} />
        <Route path="/sucsess" element={<Sucsess />} />
        {/* <Route path="/xl" element={<Xl />} /> */}
        <Route path="/t&c" element={<Tearms />} />
        <Route path="/wheel" element={<Wheel />} />
        <Route path="/xl" element={<XlPromos />} />

        <Route path="/xl/:coupon/1" element={<BtoB />} />
        <Route path="/xl/:coupon/4" element={<SuperLeague />} />
        <Route path="/xl/:coupon/5" element={<Consumer />} />
        {/* <Route path="/xl/:coupon/2" element={<Puzzle />} /> */}
        <Route path="/xl/:coupon/2" element={<XlPlus />} />
        <Route path="/xl/:coupon/:promo" element={<XlPromos />} />

        <Route path="/xl/jocker/:qrCode" element={<Jocker />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App
