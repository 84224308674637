import React, { useContext, useEffect, useState } from "react";
import Wheel from "../../components/wheel/Wheel";
import "./btob.scss";
import { AuthContext } from "../../context/AuthContext";
import Com_Login from "../../components/login/Com_Login";
import Com_Register from "../../components/login/Com_Register";
import { IoIosCloseCircleOutline } from "react-icons/io";
import background from "../../img/repart.png";
import { Link, useParams } from "react-router-dom";
import WheelCon from "../../components/wheel/WheelCon";
import g_back from "../../img/joker.jpg";
import Wheel_Jocker from "../../components/wheel/Wheel_Jocker";
import axios from "axios";

const JockerWheel = () => {
  const { qr, promotion_name } = useParams();
  const { user } = useContext(AuthContext);
  const [showBtn, setShowBtn] = useState(false);
  const [winItem, setWinItem] = useState("");
  const [apiResponse, setApiResponse] = useState(null);


  const devApiLink = "http://localhost:4348/api/v1";
  const apiLink = "https://xl.notionspromos.com/api/v1";

  const userName = user?.details?.name;
  const userId = user?.details?.id;
  const userEmail = user?.details?.email;

  const handleShowBtn = () => {
    setShowBtn(!showBtn);
  };

  const closeWindow = () => {
    setWinItem("");
  };

  const handleWinItemChange = (newWinItem) => {
    setWinItem(newWinItem);
    console.log("Win Item received from Wheel:", newWinItem);
    // Optionally, you can update the API or perform other actions here
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    const updateCode = async () => {

      const userData = {
        userName,
        userEmail,
      };
      try {
        await axios.put(
          `${apiLink}/updateCode/${userId}/${qr}/${promotion_name}/${winItem}`,
          userData
        );
      } catch (error) {
        console.log(error);
      }
    };
    updateCode();
  }, [user, winItem]);

  const places = ["1", "2", "3", "4", "5", "6", "7", "8"];

  return (
    <div
      className="__max_w "
      style={{
        // backgroundImage: `url(${g_back})`,
        backgroundColor: "black",
        backgroundSize: "cover",
        backgroundPosition: "center",
        objectFit: "contain",
      }}
    >
      <div
        className="__min_w"
        // style={{
        //   backgroundImage: `url(${background})`,
        // }}
      >
        {winItem && (
          <div className="popup">
            <div
              className="form_wrp"
            >
              <div className="close">
                <Link to={"/xl"}>
                  <IoIosCloseCircleOutline color="white" size={18} />
                </Link>
              </div>
              <div className="win_rwp">
                {winItem === "Try Again" ? (
                  <>
                    <img src={require("../../img/looes.gif")} alt="" />
                  </>
                ) : (
                  <>
                    {/* <img src={require("../../img/contrag.jpg")} alt="" /> */}
                    <p>Your Code:{qr}</p>
                    <img src={require("../../img/party.gif")} alt="" />
                    <h2>
                      Congratulations! <br /> you’ve won{" "}
                    </h2>
                    <h1>{winItem}</h1>

                    {/* <p
                      style={{
                        textAlign: "center",
                        marginBottom: "10px",
                        backgroundColor: "white",
                        padding: "10px",
                        borderRadius: "3px",
                        color: "#18191a",
                        fontSize: "14px",
                      }}
                    >
                      Congratulations! 🎉 The wheel is in your favor, and you’ve
                      won {winItem}! <br />
                      مبروك! 🎉 الحظ كان في صالحك وفزت بـ {winItem}!
                    </p> */}
                    {user ? (
                      <>
                        <p>{userName}</p>
                      </>
                    ) : (
                      <>
                        <small>
                          To claim your prize, please log in or register now!
                        </small>
                      </>
                    )}
                  </>
                )}
              </div>

              {!user ? (
                <div className="wheel_form">
                  {winItem === "Try Again" ? (
                    <>{user}</>
                  ) : (
                    <div className="wheel_form_hed">
                      {!showBtn ? (
                        <strong>Login</strong>
                      ) : (
                        <strong>Register</strong>
                      )}
                    </div>
                  )}

                  {/* <small> If you have account already login</small> */}
                  {winItem === "Try Again" ? (
                    <>
                      <p
                        style={{
                          textAlign: "center",
                          marginBottom: "10px",
                          backgroundColor: "white",
                          padding: "10px",
                          color: "#18191a",
                          fontSize: "14px",
                          borderRadius: "3px",
                        }}
                      >
                        Oh no! 😔 The wheel wasn't on your side this time, but
                        keep trying for a win <br />
                        أوه لا! 😔 الحظ لم يكن في صالحك هذه المرة، لكن استمر في
                        المحاولة للفوز.
                      </p>
                    </>
                  ) : (
                    <>
                      {!showBtn ? <Com_Login /> : <Com_Register />}
                      {/* <Com_Login /> */}

                      {!showBtn && (
                        <small>
                          {" "}
                          If you dont have account{" "}
                          <em onClick={handleShowBtn}>register</em>{" "}
                        </small>
                      )}
                      {showBtn && (
                        <small>
                          {" "}
                          If you have account{" "}
                          <em onClick={handleShowBtn}>Login</em>{" "}
                        </small>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className="spin_wheel">
                  <Link to={"/xl"}>
                    <button className="btn"> Home </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="wheel_wrp">
        {/* <div className="win_head">
            <h1>Feeling lucky today ?</h1>
            <h2>هل تشعر بأنك محظوظ اليوم؟</h2>
            <div className="your_code">
              <h2>
                <small>Your Code : </small> {coupon}
              </h2>
            </div>
          </div> */}
        <div className="wheel_back">
          {/* <img src={require("../../img/joker.jpg")} alt="" /> */}
        </div>
        <div className="wheel_con">
          <Wheel_Jocker items={places} onWinItemChange={handleWinItemChange} />
        </div>
      </div>
    </div>
  );
};

export default JockerWheel;
