import React, { useState } from 'react'

const WheelCon = ({ items, onWinItemChange }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [rotationCount, setRotationCount] = useState(100);
  const [isProcessing, setIsProcessing] = useState(false);
  const [previousItem, setPreviousItem] = useState(0);
  const [winItem, setWinItem] = useState("");
  // const [reversedItems, setReversedItems] = useState();
  const [startTime, setStartTime] = useState(0);
  let durationPerOneRotation = 0.1; // the status depends heavily on the value of durationPerOneRotation: for example, if 20 => not correct
  let spinning = selectedItem !== null ? "spinning" : "";

  const handleLottery = async () => {
    setStartTime(Date.now());
    try {
      setIsProcessing(true);
      let _selectedItem = 0;
      setSelectedItem(selectedItem);

      setTimeout(() => {
        setSelectedItem(_selectedItem);
        setRotationCount(100);
      }, 500);
      // }
    } catch (err) {
      console.log("err", err);
    }
  };

  const wheelVars = {
    "--nb-item": items?.length,
    "--selected-item": selectedItem,
    "--nb-turn": rotationCount,
    "--spinning-duration": `calc(${rotationCount} * ${durationPerOneRotation}s)`,
  };

  const handleSetRotationCount = () => {
    const elapsedTime = (Date.now() - startTime) / 1000;
    let passedItems =
      (elapsedTime / durationPerOneRotation) * 8 -
      Math.floor(elapsedTime / durationPerOneRotation) * 8;
    let exactPassedItems = Math.floor(passedItems);
    // if (passedItems - exactPassedItems > 0.5) exactPassedItems +=1;
    // console.log("exact passed items:", exactPassedItems);
    setRotationCount(0); // stop the wheel directly
    let itemWhenClickedStopButton = 9 - exactPassedItems + previousItem;
    if (itemWhenClickedStopButton > 8) itemWhenClickedStopButton -= 8;
    setSelectedItem(itemWhenClickedStopButton); // stop the wheel to default item

    // console.log("clicked Item: ", items[9 - exactPassedItems]);

    setTimeout(() => {
      setRotationCount(2);
      let selected = Math.floor(Math.random() * 8);
      setSelectedItem(selected);
      setPreviousItem(selected);
      // console.log("selected item: ", items[selected]);
      const newWinItem = items[selected];
      setWinItem(newWinItem);
      if (onWinItemChange) {
        onWinItemChange(newWinItem); // Notify parent of the new winItem
      }
      setIsProcessing(false);
    }, 5);
  };

  return (
    items &&
    items.length > 0 && (
      <>
        <div className="wheel_wrp">
          <div className="border">
            <div className="wheel-container">
              <div className={`wheel ${spinning}`} style={wheelVars}>
                {items.map((item, index) => (
                  <div
                    className="wheel-item"
                    key={index}
                    style={{ "--item-nb": index }}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className="__logo">
                <img src={require("../../img/logo.png")} alt="" />
              </div>
              <div className="border_img">
                {isProcessing ? (
                  <img src={require("../../img/border.gif")} alt="" />
                ) : (
                  <img src={require("../../img/border.png")} alt="" />
                )}
              </div>
              <div className="blackBorder"></div>
            </div>
          </div>
          <div className="wrp_btn">
            {isProcessing ? (
              <img src={require("../../img/button.gif")} alt="" />
            ) : (
              <img src={require("../../img/button.png")} alt="" />
            )}
            {!isProcessing ? (
              <span
                className="sp_btn"
                onClick={() => handleLottery()}
                disabled={isProcessing}
              >
                <h2>Spin & Win </h2>
              </span>
            ) : (
              <span
                className="sp_btn"
                onClick={handleSetRotationCount}
                disabled={!isProcessing}
              >
                <h2>Stop</h2>
              </span>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default WheelCon
