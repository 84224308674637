import React, { startTransition, useEffect, useState } from "react";
import "./Home.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import backimg from "../../img/hero.jpg";
import QRCodeScanner from "../../components/QRCodeScanner";

import ddfCode from "./DDF.js";


const Home = () => {
  const { value, promolink } = useParams();

  const [isValid, setValid] = useState(false);
  const [code_one, setCode_one] = useState();
  const [code_two, setCode_two] = useState(value || "");
  const [link, setLink] = useState(promolink || "");
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [qrData, setQrData] = useState("");

  const [scannedData, setScannedData] = useState("");

  const [splitData, setSplitData] = useState("");

  useEffect(() => {
    const url = scannedData;
    const lastValue = url.split("/").pop(); // This gets the last part of the URL
    setSplitData(lastValue);
  }, []);

  // console.log(code_two, link);

  const handleScanSuccess = (data) => {
    setScannedData(data);
    console.log("Scanned Data:", data);
  };

  const handleScan = (data) => {
    if (data) {
      setQrData(data);
    }
  };

  const apiLink = "https://api.notionspromos.com/api";

  const handleError = (err) => {
    console.error(err);
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    setCode_two((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const ddfPromoLink = "2"
  
   const hadlleSubmit = async (e) => {
     e?.preventDefault();
     if (!value) {
       try {
         const res = await axios.post(
           `${apiLink}/${code_two}`
           // `http://localhost:8800/api/${code_two}`
         );
         // Use Set for faster lookup
         const ddfCodeSet = new Set(ddfCode);

         if (ddfCodeSet.has(code_two)) {
           navigate(`/promotion/${code_two}/${ddfPromoLink}`); // If found
         } else {
           navigate(`/promotion/${code_two}/${link}`); // If not found
         }
         setApiResponse(res.data.message);

         console.log("Your code is verified");
       } catch (err) {
         setError("Please enter a valid code", err);
       }
     }
   };

   useEffect(() => {
     const testCode = async () => {
       if (!value) {
         return;
       }

       try {
         const res = await axios.post(`${apiLink}/${value}`);
         setApiResponse(res.data.message); // Set the API response message

         // Use Set for fast lookup of the code
         const ddfCodeSet = new Set(ddfCode);

         // Check if the value is in ddfCode
         if (ddfCodeSet.has(value)) {
           navigate(`/promotion/${value}/${ddfPromoLink}`); // Navigate to ddfPromoLink
         } else {
           navigate(`/promotion/${value}/${promolink}`); // Navigate to promolink if not found
         }

         console.log("Your code is verified");
       } catch (err) {
         setError("Please enter a valid code", err);
       }
     };

     testCode();
   }, [value, apiLink, navigate, promolink, ddfCode]);







  /// save onlicalstorage

  useEffect(() => {
    localStorage.setItem("Coupon_Code_One", JSON.stringify(code_one));
  }, [code_one]);

  useEffect(() => {
    localStorage.setItem("Coupon_Code_Two", JSON.stringify(code_two));
  }, [code_two, value]);

  return (
    <div
      className="__home"
      style={{
        backgroundImage: `url(${backimg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        objectFit: "contain",
        height: "80vh",
      }}
    >
      <div className="__home__wrap">
        <div className="__coupen__form">
          <div class="__brand__title">
            <span>Enter The Code and Win</span>
          </div>

          <form className="__form" onSubmit={hadlleSubmit}>
            {/* <useQrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
            /> */}
            <inputs>
              {/* <input
                type="text"
                placeholder="CDXY"
                maxLength="4"
                required
                id="code_one"
                value={code_one}
                onChange={(e) => setCode_one(e.target.value)}
              />*/}

              <input
                type="text"
                placeholder="4ydWDo4QU57e"
                maxLength="13"
                required
                id="code_two"
                value={code_two}
                onChange={(e) => setCode_two(e.target.value)}
              />
              <div className="__space">
                <p>-</p>
              </div>
              <input
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required
                type="text"
                placeholder="1"
                maxLength="8"
              />
              {/* <div className="__space">
                <p>-</p>
              </div> */}
            </inputs>
            {/* <QrGen/> */}
            <button type="submit">ENTER</button>
            <div className="err_msg">
              {error && <p>{error}</p>}
              {apiResponse && <p>{apiResponse}</p>}
            </div>
          </form>
          {/* <div>
            <QRCodeScanner onScanSuccess={handleScanSuccess} />
            {scannedData && (
              <div>
                <h2>Scanned Data:</h2>
                <p>{scannedData}</p>
              </div>
            )}
          </div> */}
        </div>
      </div>

      {/* <div className="xl__home__wrap">
        <div className="logo">
          <img src={require("../../img/xl.webp")} alt="" />
        </div>
        <Link to={"/xl"}>
          <button>EXPLORER</button>
        </Link>
      </div> */}
    </div>
  );
};

export default Home;
