import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./jocker.scss";
import { AuthContext } from "../../context/AuthContext";
import cajunImg from "../../img/cajun.png";
import chili_lime from "../../img/grilled.png";
import jalapeno_and_lime from "../../img/bbq_ribs.png";
import surache from "../../img/bbq.png";
import chili_Buffalo_Wing from "../../img/chilliLime.png";
import hot_and_sour_chili_mix from "../../img/chinese.png";
import mushroom from "../../img/greenOnion.png";
import Magic_Mushroom from "../../img/mushroom.png";
import white_cheddar from "../../img/pickledCucumber.png";
import vanilla from "../../img/ranch.png";
import chocolate from "../../img/smokey_bbq.png";
import tomato_and_herb from "../../img/sweet_paprika.png";
import feta_cheese_and_olive from "../../img/Honey&butter.png";
import wasabi_chili from "../../img/kkimchi.png";
import snow_crab from "../../img/laatar.png";
import Blue_Cheese_Blast from "../../img/blue.png";
import masala from "../../img/masala.png";
import harves from "../../img/harvest.png";
import bbq_ribs from "../../img/bbq_ribs.png";
import sushi from "../../img/tomato&herb.png";
import ranch from "../../img/ranch.png";
import fetaCheese from "../../img/fetaCheese.png";
import wasabi from "../../img/wasabi.png";
import { BsPersonCircle } from "react-icons/bs";
import back_tex from "../../img/bk_backtext.webp";
import back_tex_two from "../../img/__fire.gif";
import winImg from "../../img/WEB_in.png";
import loose from "../../img/looes.gif";
import backimg from "../../img/a.png";
import axios from "axios";

import jocker from "../../img/joker.jpg";

const Jocker = () => {
  const { qrCode } = useParams();
  const [clickedId, setClickedId] = useState("");
  const [chancesLeft, setChancesLeft] = useState(3);
  const [gameMessage, setGameMessage] = useState("");
  const [show, setShow] = useState(false);
  const [winGameMessage, setWinGameMessage] = useState("");
  const [isGameOver, setIsGameOver] = useState(false);
  const [hiddenFlavors, setHiddenFlavors] = useState([]);
  const [shuffledFlavors, setShuffledFlavors] = useState([]);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const userName = user?.details?.name;
  const email = user?.details?.email;
  const [isActive, setIsActive] = useState(false);
  const [selectFlavors, setSelectFlavors] = useState(false);
  const [flavorId, setFlavorId] = useState("");

   const devApiLink = "http://localhost:4348/api/v1";
   const apiLink = "https://xl.notionspromos.com/api/v1";


   

  const promotion_name = "jocker";

  const coupon = qrCode;

  const handleTouchStart = () => {
    setIsActive(true);
  };

  const handleTouchEnd = () => {
    setIsActive(false);
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const rect = e.currentTarget.getBoundingClientRect();
    if (
      touch.clientX < rect.left ||
      touch.clientX > rect.right ||
      touch.clientY < rect.top ||
      touch.clientY > rect.bottom
    ) {
      setIsActive(false);
    }
  };

  // console.log(clickedId);

  useEffect(() => {
    if (!clickedId) {
      return;
    } else {
      const checkCode = async () => {
        try {
          await axios
            .get(`${apiLink}/jocker/${coupon}/${clickedId}`)
            .then((response) => {
              setSelectFlavors(response.data.message);
              // console.log(response.data.message);
            });
        } catch (error) {}
      };
      checkCode();
    }
  }, [clickedId]);

  useEffect(() => {
    if (!coupon) {
      return;
    }
    const moveCodeToUseCode = async () => {
      try {
        await axios.get(`${apiLink}/move_ues_code/${coupon}`);
      } catch (error) {
        console.log(error);
      }
    };
    moveCodeToUseCode();
  }, [coupon]);

  const flavors = [
    {
      id: "Magic_Mushroom",
      value: selectFlavors,
      img: Magic_Mushroom,
    },
    {
      id: "Blue_Cheese_Blast",
      value: selectFlavors,
      img: Blue_Cheese_Blast,
    },
    {
      id: "Sweet_Paprika_Pop",
      value: selectFlavors,
      img: fetaCheese,
    },

    {
      id: "Wasabi_Whirlwind",
      value: selectFlavors,
      img: chili_lime,
    },
    {
      id: "Five_Spice_Fusion",
      value: selectFlavors,
      img: jalapeno_and_lime,
    },
    {
      id: "Pickled_Onion_Punch",
      value: selectFlavors,
      img: surache,
    },
    {
      id: "Crunchy_Pickled_Cucumber",
      value: selectFlavors,
      img: chili_Buffalo_Wing,
    },
    {
      id: "Kimchi_Kick",
      value: selectFlavors,
      img: hot_and_sour_chili_mix,
    },
    {
      id: "Truffle_Treasure",
      value: selectFlavors,
      img: mushroom,
    },

    {
      id: "Ranch_Rush",
      value: selectFlavors,
      img: mushroom,
    },
    {
      id: "Zesty_Zaatar",
      value: selectFlavors,
      img: white_cheddar,
    },
    {
      id: "Classic_BBQ",
      value: selectFlavors,
      img: vanilla,
    },
    {
      id: "Smoky_BBQ_Sensation",
      value: selectFlavors,
      img: chocolate,
    },
  ];

  // const [correctFlavor,setCorrectFlavor] = useState("")

  const correctFlavor = flavors.find((flavor) => flavor.value === true);

  useEffect(() => {
    if (!clickedId) {
      return;
    }
    navigate(`/wheel/${coupon}/${promotion_name}`,{ replace: true });
  }, [correctFlavor]);

  const handleClick = (id) => {
    if (isGameOver) return;
    setClickedId(id);

    if (chancesLeft > 0) {
      // Check if the correct flavor has been clicked
      if (correctFlavor?.value === true) {
        return;
        // setCorrectFlavor()
      } else {
        // Update chances left
        setChancesLeft((prev) => prev - 1);
        setHiddenFlavors((prev) => [...prev, id]);

        if (chancesLeft - 1 === 0) {
          setGameMessage(
            `Sorry, you've run out of chances` 
          );
          setIsGameOver(true);
        } else {
          setGameMessage(
            `Wrong guess. You have ${chancesLeft - 1} chances left.`
          );
        }
      }
    }
  };

  // Function to shuffle an array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Shuffle flavors on component mount
  useEffect(() => {
    setShuffledFlavors(shuffleArray([...flavors]));
  }, []);

  return (
    <div
      className="__max_w"
      style={{
        // backgroundImage: `url(${jocker})`,
        backgroundColor: "black",
        backgroundSize: "cover",
        backgroundPosition: "top",
        objectFit: "contain",
      }}
    >
      {show && (
        <div
          className="jocker_win"
          style={{
            backgroundImage: `url(${winImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "contain",
          }}
        >
          <div
            className="win_msg_wrp"
            // style={{
            //   backgroundImage: `url(${back_tex_two})`,
            //   backgroundSize: "cover",
            //   backgroundPosition: "center",
            //   objectFit: "contain",
            // }}
          >
            <Link
              to={(`/wheel/${coupon}/${promotion_name}`, { replace: true })}
            >
              <button>SPIN THE WHEEL</button>
            </Link>
          </div>
        </div>
      )}

      {isGameOver !== show && (
        <div
          className="jocker_win"
          // style={{
          //   backgroundImage: `url(${winImg})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   objectFit: "contain",
          // }}
        >
          <div
            className="win_msg_wrp"
            // style={{
            //   backgroundImage: `url(${loose})`,
            //   backgroundSize: "cover",
            //   backgroundPosition: "center",
            //   objectFit: "contain",
            // }}
          >
            <p style={{ color: "white", textAlign: "center" }}>{gameMessage}</p>
            <br />
            <span>
              Thank for Participation <br /> Try Again
            </span>
            <br />
            {/* <img src={`${loose}`} alt="" /> */}
            <br />
            <Link to={"/xl"}>
              <button className="btn"> Home </button>
            </Link>
          </div>
        </div>
      )}

      <div
        className="__min_w"
        // style={{
        //   backgroundImage: `url(${backimg})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   objectFit: "contain",
        // }}
      >
        <div className="jocker">
          <div className="j_head">
            <img src={require("../../img/banner.png")} alt="" />
          </div>
          <div className="jocker_head">
            <p>
              <div className="jocker_head_pro">
                <BsPersonCircle />
                {userName}
              </div>
              Your Code : <em>{coupon}</em>
            </p>
            <div className="game_status">
              {/* <p>
                <em>{gameMessage}</em>
              </p> */}
            </div>
            <p>
              <div className="_jocker_head_pro">
                <span className="_span">{chancesLeft}</span>
              </div>
              Chances left
            </p>
          </div>
          <div className="game_status">
            <p>
              <em>
                {!gameMessage ? (
                  <>
                    {" "}
                    <p>You have 3 chance to play game</p>{" "}
                  </>
                ) : (
                  <>{gameMessage}</>
                )}
              </em>
            </p>
          </div>

          <div className="card_wrp">
            {shuffledFlavors.map((flavor) => (
              <div
                key={flavor.id}
                className={`_f_card ${isActive ? "active" : ""} ${
                  hiddenFlavors.includes(flavor.id) ? "disabled" : ""
                }`}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
                id={flavor.id}
                onClick={() => handleClick(flavor.id)}
              >
                <img src={flavor.img} alt="" />
              </div>
            ))}
          </div>
          <div className="note">
            <p>
              <em>Note:</em> Lorem ipsum dolor, sit amet consectetur adipisicing
              elit. Labore, ratione pariatur. Dolores, quae? Debitis quos
              perspiciatis numquam sunt consectetur error voluptates deserunt,
              ipsum, modi explicabo praesentium adipisci et. Nostrum sint
              molestias maiores ex. Debitis harum iusto voluptas libero quis
              repudiandae. Numquam velit temporibus voluptates! Nobis, quam et
              in expedita natus autem dolor aut esse numquam, nulla
              exercitationem sequi. Voluptates saepe error iure ducimus! Ab
              temporibus magnam ducimus error suscipit, cumque voluptatibus odit
              tenetur, nobis ipsam, est illum. Culpa consectetur doloribus
              quidem incidunt consequuntur quos qui sapiente, ex nemo hic
              consequatur possimus repellendus architecto quam saepe quasi
              quibusdam aliquam vel ipsa?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jocker;
