import React from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom';

const Footer = () => {

  const scrillTop =()=>{
    window.scroll(0,0)
  }
  return (
    <div className=" __footer">
      <div className="__container">
        <a href="https://www.notionsgroup.com/" target="blank">
          <div className="__footWrap">
            <div className="__footSection">
              <img src={require("../../img/logo_w.png")} />
              <div className="_f_des">
                <p>
                  Passion is the force that drives us reason is what steers our
                  endeavours. We put our hearts and our minds in every little
                  detail of the business to achieve the best results. We are{" "}
                </p>
                <br />
                <p>Notions Group. Copyright © 2023</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Footer
