import React from 'react'
import "./superLeague.scss";
import background from "../../img/super.jpg";

const SuperLeague = () => {
  return (
    <div
      className="__max_w"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div className="__min_w">
        <div className="super_wrp">
          <div className="super_head">
            <div className="super_itm">
              <img src={require("../../img/xl.webp")} alt="" />
            </div>
            <div className="super_itm">
              <h1>
                NATIONAL FLAVORS <br /> <small>SUPER-LEAGUE</small>{" "}
              </h1>
            </div>
            <div className="super_itm">
              <p>Welcome</p>
            </div>
          </div>

          <div className="gifts_wrp">
            <div className="gift_img">
              <img src={require("../../img/gift_1.jpg")} alt="" />
            </div>
            <div className="gift_img">
              <img src={require("../../img/gift_2.jpg")} alt="" />
            </div>
            <div className="gift_img">
              <img src={require("../../img/gift_3.jpg")} alt="" />
            </div>
            <div className="gift_img">
              <img src={require("../../img/gift_4.jpg")} alt="" />
            </div>
            <div className="gift_img">
              <img src={require("../../img/gift_5.jpg")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperLeague
