import React from 'react'
import './navBar.scss'
import { Link } from 'react-router-dom';

const NavBar = () => {
  return (
    <div className="sub_nav">
      <Link to={"/xl-plus"}>
        <span>XLPLUS</span>
      </Link>
      <Link to={"/xl-twenty-five"}>
        <span>GET 25%</span>
      </Link>
      <Link to={"/xl-ranks"}>
        <span>CLIMB THE RANKS</span>
      </Link>
      <Link to={"/xl-consumer-rank"}>
        <span>CONSUMER RANK</span>
      </Link>
    </div>
  );
}

export default NavBar
